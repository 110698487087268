@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

footer {
  --black-gradient: linear-gradient(144.39deg,#fff -278.56%,#6d6d6d -78.47%,#11101d 91.61%);
}